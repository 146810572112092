.container-login {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #333;
  height: 100vh;
}

.container-login button {
  background-color: #333;
}

.container-login button:hover {
  background-color: #351414;
}

.container-login a {
  color: #333;
}

.container-login a:hover {
  color: #351414;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media all and (min-width: 480px) {
  .login {
    padding: 10px 0px;
    width: 350px;
  }
}

.imgLogo {
  width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.link-trial-user {
  color: #464646;
  text-decoration: none;
}
