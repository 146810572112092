.menu {
    border-right: 1px solid silver;
    /* height: 100%; */
}

.body {
    height: 93.5vh;
    overflow: auto;
}

.body .menu h3, .body h4 {
    margin-bottom: 0px;
}

.body .menu .header h3 {
    color: black;
    font-size: 14px;
    font-weight: bold;
}

.body .menu h3 {
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.body h4 {
    font-size: 14px;
}

.menu .item, .menu .subitem, .menu .header {
    padding: 20px 15px
}

.menu .item, .item-mobile{
    background-color: #5C5F62;
    color: white;
}

.subitem {
    cursor: pointer;
}

.subitem:hover, .selected {
    background-color: #DBDDE0;
}

.item-mobile {
    padding: 15px 5px 15px 5px;
}

.subitem-mobile {
    padding: 2px 2px 2px 2px;
}

.circularProgress {
    width: 40px;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 10px
}

.body .MuiFormHelperText-root {
    margin-left: 0px
}

.body .formGroup {
    margin-bottom: 15px;
}

.body span {
    font-size: 12px;
}

.body span.MuiCheckbox-root, .body .MuiRadio-colorPrimary {
    padding: 0px 9px
}

.break-word {
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
  