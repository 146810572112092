button.btn-default {
  color: black;
  border: 1px solid #8c9196;
  text-transform: none;
  font-weight: 500;
  padding-inline: 1rem;
}

button.btn-default:hover {
  background-color: #eeeeee;
}

button.btn-action {
  color: white;
  background-color: #02E12B;
  border: 1px solid #02E12B;
  border-radius: 8px;
  box-shadow:  
  0px 2px 1.7000000476837158px -2px #00000033,
  0px 0px 0.699999988079071px 0px #00000024,
  0px 4px 9.100000381469727px 0px #0000001F;
  text-transform: none;
  font-weight: 400;
  padding: 10px 16px 10px 16px;
}

button.btn-action:hover {
  background-color: #198038;
}

button.btn-action:active {
  background-color: #0E6027;
}

button.btn-reprove {
  color: white;
  background-color: #E74C3C;
  /* border: 1px solid #bb2307; */
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  text-transform: none;
  font-weight: 400;
  padding-inline: 1rem;
}

button.btn-reprove:hover {
  background-color: #c62828;
}

button.btn-gray {
  align-items: center;
  color: #888888;
  box-shadow: 0px 2px 1.7000000476837158px -2px #00000033,
  0px 0px 0.699999988079071px 0px #00000024,
  0px 4px 9.100000381469727px 0px #0000001F;
  background-color: #FFFFFF;
  border: 1px solid #888888;
  border-radius: 8px;
  text-transform: none;
  font-weight: 400;
  padding: 10px 16px 10px 16px;
}

button.btn-gray:hover {
  background-color: #f5f5f5;
}

button.btn-white {
  color: #000;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 1.7px -2px #00000033,
              0px 0px 0.7px 0px #00000024,
              0px 4px 9.1px 0px #0000001F;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  text-transform: none;
  font-weight: 400;
  padding: 10px 16px 10px 16px;
}

button.btn-white:hover {
  background-color: #f5f5f5;
}

button.fullwidth {
  height: 36px;
  width: 100%;
}

button.large {
  width: 172px;
  height: 36px;
}
button.medium {
  width: 90px;
  height: 36px;
}

button.small {
  height: 28px;
  padding: 6px 16px 6px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
}

li:hover {
  background-color: #f5f5f5 !important;
}

li.Mui-selected {
  background-color: #dfdfdf !important;
}

button.MuiPaginationItem-root:hover {
  background-color: rgba(0, 0, 0, 0.16) !important;
}

.logo-img {
  width: 80%;
}

a.link {
  margin-top: 2rem;
  color: #333 !important;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 999;
}

.ql-editor {
  overflow-y: auto;
  resize: vertical;
  min-height: 100px;
}

.quill.ql-error {
  .ql-toolbar {
    border-top: 1px solid red;
    border-left: 1px solid red;
    border-right: 1px solid red;
  }
  .ql-container {
    border-left: 1px solid red;
    border-right: 1px solid red;
    border-bottom: 1px solid red;
  }
}

.ql-container {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-bottom: 2rem;
}

.ql-snow.ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.ql-indent-1 {
  padding-left: 2em;
  list-style-position: inside;
}

.ql-indent-2 {
  padding-left: 4em;
  list-style-position: inside;
}

.ql-indent-3 {
  padding-left: 6em;
  list-style-position: inside;
}

.ql-indent-4 {
  padding-left: 8em;
  list-style-position: inside;
}