@media (max-width: 1450px) {
    .performanceCredits {
        margin-bottom: 8px;
    } 
}

.circular-performance {
    justify-content: center;
}

@media (max-width: 1535px) {
    .circular-performance {
        justify-content: flex-start;
    }
}

@media (max-width: 639px) {
    .icons {
        display: none;
    }
}
@media (min-width: 640px) {
    .metrics {
        margin-bottom: 16px;
    }
    .taxa-conversao {
        display: none;
    }
    
}