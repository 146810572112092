body {
  background-color: #fafafa !important;
  font-family: "Roboto" !important;
}

nav.sidebar {
  max-height: 100vh;
  overflow: auto;
}

a {
  /* color: inherit !important */
}

.logo {
  width: 70%;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 2rem;
}

.MuiTableCell-root a {
  color: #333;
}

nav.navbar a {
  color: white;
}

nav.navbar .dropdown-menu a {
  color: black;
}

nav a:hover {
  color: #e3e3e3;
}

nav .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.5%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.header-logo {
  flex-direction: row !important;
}

.container-dash {
  transition: all 0.3s;
}

.bottom-menu {
  width: 230px;
  position: fixed !important;
  bottom: 0;
  padding: 0;
  z-index: 999;
  transition: all 0.3s;
  padding-bottom: 0 !important;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: #2b2b2b;
  min-width: 250px;
  max-width: 250px;
  min-height: 100%;
  transition: all 0.3s;
  position: fixed;
  z-index: 999;
}

.sidebar .ul {
  padding: 0rem 1rem;
}

.sidebar .ul .li, .bottom-menu .li {
  padding: 0.5rem 1rem;
  color: #d1d5db !important;
  text-decoration: none;
  border-radius: 8px;
}

.sidebar .ul .li svg, .bottom-menu .li svg {
  color: #d1d5db;
}

.sidebar .ul .li:hover svg, .bottom-menu .li:hover svg {
  color: #02E12B;
}

.sidebar .ul .li:hover,
.sidebar .ul .active,
.bottom-menu .li:hover {
  color: #02E12B !important;
  background-color: rgba(255, 255, 255, 0.08) !important;
}
.sidebar ul ul a {
  padding-left: 2.5rem;
  background-color: #1e2835;
}
.sidebar-avatar {
  height: 24px !important;
  width: 24px !important;
}
.sidebar-select div {
  display: flex;
  gap: 5px;
}
/*Seletor utilizado para ativar o botão ocultar menu em PC*/
.sidebar.toggled, .bottom-menu.toggled {
  margin-left: -250px;
}

/* @media (max-width: 768px){
    .sidebar{
        margin-left: -250px;
    }
    .sidebar.toggled{
        margin-left: 0px;
    }
} */

.sidebar [data-toggle="collapse"] {
  position: relative;
}
.sidebar [data-toggle="collapse"]:before {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 1rem;
}
.sidebar [aria-expanded="true"] {
  background-color: #19222d;
}
.sidebar-header {
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 2rem 1rem 1rem 1rem;
  background: #2b2b2b;
}
.content {
  width: 100%;
}
.titulo {
  font-size: 2rem !important;
}

.img-perfil {
  position: relative;
  display: inline-block;
}

.edit {
  padding-top: 7px;
  position: absolute;
  padding-right: 7px;
  top: 0px;
  right: 0px;
  display: none;
}

.img-perfil:hover .edit {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

/* */
.start-node,
.end-node {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  line-height: 64px;
  color: #fff;
  text-align: center;
}

.start-node {
  background-color: #338aff;
}

.end-node {
  background-color: #666;
}

.other-node,
.condition-node {
  width: 224px;
  color: #666;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  padding: 16px;
  text-align: center;
}

.other-node {
  background-color: #351414;
  display: flex;
  flex-direction: column;
}

.condition-node {
  background-color: #333;
}

.node-status-error {
  box-shadow: 0 0 8px #ff4d4f !important;
}

.node-configuring {
  box-shadow: 0 0 8px #338aff !important;
}

#workflow textarea,
#workflow input,
#workflow select {
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  width: 100%;
}

#workflow textarea::placeholder,
#workflow input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

#workflow .flow-builder-content {
  background-color: transparent !important;
}

nav .page-item.active .page-link {
  background-color: #333;
  border-color: #333;
}

.btn.btn-custom-1,
.btn.btn-primary {
  background-color: #333;
  color: white;
}

.btn.btn-custom-1:hover,
.btn.btn-primary:hover {
  background-color: #351414;
  color: white;
}

.btn.btn-custom-2 {
  background-color: #351414;
  color: white;
}

.btn.btn-custom-2:hover {
  background-color: #333;
  color: white;
}

.btn.btn-outline-info {
  border-color: #333;
  color: #333;
}

.btn.btn-outline-info:hover {
  background-color: #333;
  color: white;
  border-color: white;
}

a:hover {
  color: #351414;
  text-decoration: none !important;
}

.fieldDate {
  margin-top: 20px !important;
}

.fieldDate label {
  color: white;
}

.fieldDate input {
  color: white;
}

.optionsForm input,
.optionsForm label {
  /* padding: 0px; */
}

.other-node h5,
.condition-node h5,
.other-node small,
.condition-node small {
  color: white;
  margin: 0;
}

.other-node ul {
  color: white;
  font-size: 12px;
  text-align: left;
}

.sidebar hr {
  border-color: rgba(255, 255, 255, 0.12);
}

/* */
.chip-status {
  background-color: #757575;
  color: white;
  display: inline;
  font-size: 12px;
  border-radius: 4px;
  /* margin-left: 10px; */
  padding: 4px 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
}

details summary {
  list-style: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::marker {
  display: none;
}

.card_progress {
  padding: 20px 20px 0px 20px;
}

button.saveButton,
button.saveButton:hover {
  color: white;
  background-color: #008060;
  text-transform: capitalize;
}

button.cancelButton,
button.cancelButton:hover {
  color: #202223;
  border: 1px solid silver;
  text-transform: capitalize;
  background-color: transparent;
}

.dashboard {
  padding: 1rem 1.5rem;
}

@media screen and (max-width: 768px) {
  .dashboard {
    margin-bottom: 4rem;
  }
  
}

.alignCenterVertical {
  display: flex;
  align-items: center;
}

.clearInput {
  border: 1px solid silver;
  padding: 0px;
}

.clearInput.inputH3 {
  font-size: 1.75rem;
}

.clearInput:focus {
  outline: none;
}

.MuiTablePagination-toolbar p {
  margin-bottom: 0px;
}

/* Button */

button.buttonGreen {
  background-color: #02E12B;
  color: white;
  text-transform: capitalize;
  padding: 8px 40px;
  font-size: 16px;
}

button.buttonGreen:hover {
  background-color: #39985F;
  color: white;
}

button.buttonGreen:disabled {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
}

.slick-slide {
  padding: 10px;
  transition: all ease-in-out 0.3s;
}

.slick-slide.slick-active {
  padding: 0px;
}

.dashboard-body {
  padding: 1rem 1.5rem;
}

.tag-user {
  background: rgb(44, 130, 210);
  padding: 0 5px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  background-color: rgb(44, 130, 210);
  border-radius: 16px;
  white-space: nowrap;
}

.MuiChip-sizeSmall {
  font-size: 11px !important;
}

.productfruits--container{
  height: 0 !important;
  /* display: none !important; */
}

::-webkit-calendar-picker-indicator  {
  filter: invert(62%) sepia(6%) saturate(7%) hue-rotate(317deg) brightness(86%) contrast(87%);
}