.platinum-log-container {
    padding: 4px;
}

.search-box {
    flex-grow: 1;
}

.padding {
    padding: 0 1.5rem;
}

.card-general {
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-grid-stack {
    position: relative;
    padding: 40px 26px;
}

.internal-box {
    width: 100%;
    text-align: center;
}

.box-svg-typography {
    margin-top: 8px;
}

.typography-numbers {
    margin-top: 16px;
    font-size: 22px;
    font-weight: 600;
}

.item-grid-stack {
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.item-grid-stack::before {
    content: "";
    position: absolute;
    width: 100%;
    background-color: #F3F3F3;
    height: 4px;
    top: 50px;
    left: calc(100% - 40px);
    z-index: -1;
    overflow: hidden;
}

.item-grid-stack:last-child::before {
    right: 50px;
    left: auto;
    width: 100%;
}

.item-grid-stack:first-child::before {
    left: 50%;
    width: 100%;
}

.box-status {
    text-align: center;
    flex-grow: 1;

    .number {
        margin: 8px 0px;
        font-size: 18px;
        font-weight: 600;

        small {
            color: red;
            font-size: 12px;
        }
    }

    .title {
        font-size: 12px;
        font-weight: 400;
    }

    .mediaHours {
        font-size: 12px;
        font-weight: 400;
        color: #787878;
    }

    .icon {
        background-color: #F3F3F3;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        margin: 0 auto;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.box-status-error,
.box-media-time {
    width: 100%;
    text-align: center;
    font-size: 14px;

    .card {
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
        border-bottom: 2px solid #888;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2F2F2F;
        height: 125px;

        .title {
            font-size: 12px;
            margin-top: 6px;
        }

        .number {
            margin-top: 2px;
            font-size: 22px;
            font-weight: 600;
        }

    }

    .card-red {
        border-bottom-color: #FF0000;
    }

    .card-yellow {
        border-bottom-color: #FFC700;
    }

}

.box-status-error {
    .card {
        cursor: pointer;
    }
}

.box-media-time {
    margin-top: 16px;
    padding: 8px;

    .card {
        border-bottom: 2px solid #B196D9;

        .title {
            font-size: 16px;
            margin-bottom: 16px;
        }
    }

}

@media (max-width: 1199px) {
    .card {
        min-height: 120px;
    }
}

@media (max-width: 380px) {
    .item-grid-stack:last-child::before {
        display: none;
    }
}



.orderTable {

    th,
    td {
        border: 1px solid rgba(224, 224, 224, 1);
    }

    th {
        border-radius: 20px
    }
}

.fixed-filter-bar {
    position: sticky;
    top: 0;
    padding: 20px 0px;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    // transiction no padding e no background
    transition: padding 0.3s;

}

.item-details div {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 10px 0px;
    word-break: break-all;
}

.internal-events {

    .main-grid-stack {
        padding: 30px 20px;

        .title {
            margin: 10px 0px;
            font-size: 14px;
        }

        .mediaHours {
            font-size: 16px;
            color: #2F2F2F
        }
    }
}