.chat-container {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
}

.chat-sidebar-user {
  background-color: #FFFFFF;
  width: 350px;
  position: fixed;
  z-index: 5;
}

.chat-sidebar {
  background-color: #F3F3F3;
  width: 350px;
  min-width: 350px;
  z-index: 5;
  height: 100%;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 0px 10px 0px #0000001A;
  border-right: 1px solid #e5e7eb;
}

@media screen and (max-width: 768px) {
  .chat-sidebar {
    width: 100%;
    box-shadow: none;
    background-color: #FFFFFF;
    border: none;
  }
  
  #chat-input-quill .ql-editor{
    padding-top: 0.25rem !important;
  }

  .dangerous-html p{
    font-size: 16px;
  }
}

.drawer-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  height: 100%; 
  overflow: auto;
  background: #fff;
  transition: transform 0.5s ease-out;
  transform: translateX(100%);
}

.drawer-container.open {
  transform: translateX(0);
}

.chat-sidebar.minimized {
  width: 0;
  overflow: hidden;
  min-width: 0;
}

.chat-input {
  border: none;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  padding: 0;
  margin-bottom: 8px;
  width: 100%;
  resize: none;
  overflow-y: auto;
}

.chat-input textarea {
  border: none;
  outline: 0;
}

.chat-input__highlighter {
  margin: 1px 0px 0px 2px;
}

.mention-text, .mention {
  color: #02E12B;
}

#chat-input-quill .ql-mention-list-container {
  background-color: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  z-index: 1000;
}

#chat-input-quill .ql-mention-list-container ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#chat-input-quill .ql-editor{
  min-height: 50px;
  max-height: 250px;
  overflow: auto;
}

#chat-input-quill .ql-mention-denotation-char{
  color: #02E12B;
}

#chat-input-quill .ql-container.ql-snow {
  border: none;
}

#chat-input-quill .mention {
  color: #02E12B;
}

.dangerous-html p{
  margin: 0;
  
}